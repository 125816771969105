<template>
  <nav class="navbar">
      <img src="@/assets/logo.svg" alt="Logotipo da Escola" class="logo-navbar">
      <div class="navbar-links">
        <span class="user-greeting">Olá, {{ parentName }}</span>
        <span class="time-left">{{ sessionTimeLeft }}</span>
        <button @click="logout">Sair</button>
      </div>
    </nav>
  <div class="page-container">
    <div class="student-info">
    <h3 class="student-name">Aluno: {{ studentName }} - <button class="student-select-button" @click="goToStudentList">Seleção de aluno</button></h3>
    <span class="student-details">{{ StudentSerie }} - {{ StudentTurno }}</span>
  </div>

    <form-wizard @onComplete="finalizeEnrollment"  :disabled="isSubmitting"  :start-index="0"  color="#333" 

        :nextButtonText="'Próximo'"   
        :backButtonText="'Voltar'"
      :finishButtonText="'Concluir'"
   >
   <tab-content title="Passo 1 - Selecione 1º curso:" :before-change="beforeTabSwitch" icon="fa fa-angle-double-right">
  <div v-if="isLoading" class="loading-indicator">
    Carregando...
  </div>
  <template v-else>
    <!-- Verifique se o aluno já possui matrícula em alguma atividade opcional -->
    <div v-if="optionalEnrollments && optionalEnrollments.QTDMATRICULASSEMPLANOPAGAMENTO > 0" class="enrollment-message">
      Aluno(a) já possui matrícula em atividade opcional.
    </div>
    <!-- Caso contrário, exiba as opções de seleção do curso -->
    <div v-else>
      <div class="select-container">
        <label for="courseSelect">Selecione 1º curso:</label>
        <select id="courseSelect" v-model="selectedCourse">
          <option v-for="course in courses" :value="course.CODCURSO" :key="course.CODCURSO">
            {{ course.ATIVIDADE }}
          </option>
        </select>
      </div>
      <div class="class-container" v-if="activities?.length > 0">
        <label>Selecione uma turma:</label>
        <div class="radio-group">
          <label v-for="activity in activities" :key="activity.IDHABILITACAOFILIAL" class="radio-label">
            <input type="radio" :value="activity.IDHABILITACAOFILIAL" v-model="selectedClassId" class="custom-radio" />
            {{ activity.HORARIO }} - {{ activity.TURNO }} <p> {{ activity.GENEROTURMA }}</p>
            <div v-if="activity.MAXALUNOS === activity.QTD_ALU_TURMA" class="wait-list">
              Lista de espera
            </div>
          </label>
        </div>
      </div>
    </div>
  </template>
</tab-content>
   
<tab-content title="Passo 2 - Selecione 2º curso:" icon="fa fa-angle-double-right">
  <div v-if="isLoading" class="loading-indicator">
    Carregando...
  </div>
  <template v-else>
    <!-- Mostrar mensagem se o aluno já possui matrícula suficiente em atividades opcionais -->
    <div v-if="optionalEnrollments && optionalEnrollments.QTDMATRICULASSEMPLANOPAGAMENTO == 2|| !canSelectSecondOptionalCourse" class="enrollment-message">
      Aluno(a) já possui matrícula em atividade opcional ou é do ensino médio com um curso opcional.
    </div>
    <!-- Caso contrário, mostrar seleção de curso -->
    <div v-else>
      <div class="select-container">
        <label for="courseSelect2">Selecione 2º curso:</label>
        <select id="courseSelect2" v-model="selectedCourse2">
          <option v-for="course in coursesForSecondStep" :value="course.CODCURSO" :key="course.CODCURSO">
            {{ course.ATIVIDADE }}
          </option>
        </select>
      </div>
      <div class="class-container" v-if="activities2?.length > 0">
        <label>Selecione uma turma:</label>
        <div class="radio-group">
          <label v-for="activity in activities2" :key="activity.IDHABILITACAOFILIAL" class="radio-label">
            <input type="radio" :value="activity.IDHABILITACAOFILIAL" v-model="selectedClassId2" class="custom-radio" />
            {{ activity.HORARIO }} - {{ activity.TURNO }} <p> {{ activity.GENEROTURMA }}</p>
            <div v-if="activity.MAXALUNOS === activity.QTD_ALU_TURMA" class="wait-list">
              Lista de espera
            </div>
          </label>
        </div>
      </div>
    </div>
  </template>
</tab-content>



  <tab-content title="Passo 3 - Selecione os cursos extras" icon="fa fa-angle-double-right">

    <div v-if="paidCourses.length === 0" class="enrollment-message">
      No momento não há cursos extras disponíveis.
    </div>
    <!-- Caso contrário, mostrar seleção de curso -->
    <div v-else>
  <!-- Adicionar uma nota sobre o preço aqui -->
  <div class="course-price-notice">
  <p>Por favor, atente-se ao valor mensal indicado para cada turma selecionada.</p>
</div>

  <div v-for="course in paidCourses" :key="course.CODCURSO" class="course-selection">
    <input type="checkbox" :id="`extra-course-${course.CODCURSO}`" :value="course.CODCURSO" v-model="selectedExtraCourses">
    <label :for="`extra-course-${course.CODCURSO}`">{{ course.ATIVIDADE }}</label>

    <!-- Verifica se o curso está selecionado e se está carregando -->
    <div v-if="selectedExtraCourses.includes(course.CODCURSO)">
      <!-- Exibir 'Carregando...' se isLoading estiver ativo -->
      <div v-if="isLoading" class="loading-message">Carregando...</div>
      <!-- Exibir turmas se isLoading estiver inativo -->
      <div v-else class="turmas-container" :id="`turmas-${course.CODCURSO}`">
        <label :for="`turmas-${course.CODCURSO}`">Selecione as turmas para {{ course.ATIVIDADE }}:</label>
        <div v-for="activity in paidActivities[course.CODCURSO]" :key="activity.IDHABILITACAOFILIAL" class="turma-selection">
          <input type="checkbox" :id="`turma-${activity.IDHABILITACAOFILIAL}`" :value="activity.IDHABILITACAOFILIAL" v-model="selectedExtraTurmas">
          <label :for="`turma-${activity.IDHABILITACAOFILIAL}`">{{ activity.HORARIO }} - {{ activity.TURNO }}</label>
          <!-- Exibir o preço por turma -->
          <span class="turma-price"> {{formatCurrency(course.VALOR_PARCELA)}} por mês</span>
          <div v-if="activity.MAXALUNOS === activity.QTD_ALU_TURMA" class="wait-list">
            Lista de espera
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</tab-content>


<tab-content title="Passo 4 - Conclusão" icon="fa fa-flag">
  <div class="resumo-matricula">
    <h2>Resumo da Matrícula</h2>
      <!-- Indicador de carregamento -->
      <div v-if="isLoading" class="loading-message">Registrando matriculas nas turmas selecionadas... Aguarde, por favor</div>
    <div v-else>
    <section>
      <h3>Cursos Regulares Selecionados</h3>
      <p>Curso 1: {{ findCourseName(selectedCourse) }} - Turma: {{ findClassName(selectedClassId) }}</p>
      <p>Curso 2: {{ findCourseName(selectedCourse2) }} - Turma: {{ findClassName(selectedClassId2) }}</p>
    </section>
    
    <section v-if="selectedExtraCourses.length > 0">
      <h3>Cursos Extras Selecionados</h3>
      <ul>
        <li v-for="courseCode in selectedExtraCourses" :key="courseCode">
          {{ findCourseName(courseCode) }}:
          <ul>
            <li v-for="classId in selectedExtraTurmas" :key="classId">
              <span v-if="isClassFromCourse(classId, courseCode)">
                Turma: {{ findClassName(classId) }} -   <span class="valor">Valor: {{ formatCurrency(findCourseValue(courseCode, classId)) }}</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
      <p class="total">Total: {{ calculateTotal }}</p>
    </section>
  </div>

  </div>
</tab-content>
  </form-wizard>

   <!-- Modal de Termos de Natação -->
<div v-if="showSwimmingTermsModal" class="terms-modal">
  <div class="terms-modal-content">
    <h2>Termos para Aulas de Natação</h2>
    <div class="terms-text">
      <h3>Exame Dermatológico / Declaração de Saúde</h3>
      <p>
        Conforme legislação vigente, o aluno não pode frequentar as aulas de natação sem apresentação da via original do atestado médico (dermatológico).
      </p>
      <p>
        O pagamento da taxa de exame médico prevê a realização deste na escola, e deve ser renovado no segundo semestre. Caso não seja feito na escola, o aluno deve trazer atestado de seu médico particular até o dia 31/01/2025.
      </p>
      <p>
        Para a efetivação da inscrição na modalidade de natação, será cobrada uma taxa de <strong>R$ 65,00 (cinquenta e cinco reais)</strong> no boleto da mensalidade de fevereiro, referente ao exame médico dermatológico.
      </p>
    </div>
    <div class="terms-actions">
      <button @click="agreeToSwimmingTerms" class="terms-agree-button">Concordar e Continuar</button>
      <button @click="cancelSwimmingTerms" class="terms-cancel-button">Cancelar</button>
    </div>
  </div>
</div>
  <!-- Modal de Termos de Natação -->
</div>
</template>

<script lang="ts">
import { ref, onMounted, watch, computed  } from 'vue';
import { FormWizard, TabContent } from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { isSessionExpired } from '../auth';

interface Course {
  CODCURSO: string;
  ATIVIDADE: string;
  // Adicione outras propriedades conforme necessário
}

interface Activity {
  // substitua pelos campos reais que suas atividades têm
  IDHABILITACAOFILIAL: string;
  HORARIO: string;
  CODHABILITACAO: string;
  ATIVIDADE: string;
  ANO: string;
  TURNO: string;
  GENEROTURMA: string;
  VALOR_PARCELA: string;
  MAXALUNOS: number;
  QTD_ALU_TURMA: number;
  // ... outros campos
}

interface PaidActivities {
  [key: string]: Activity[];
}


export default {
  components: {
    FormWizard,
    TabContent
  },

  
  setup() {
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const courses = ref<Course[]>([]);
    const allCourses = ref<Course[]>([]);
    const coursesForSecondStep = ref<Course[]>([]); // Nova variável para cursos do segundo passo

    const selectedFirstCourse = ref(''); // Adicione esta linha
    const selectedCourse = ref('');
    const selectedClassId = ref('');
    
    const selectedCourse2 = ref('');
    const selectedClassId2 = ref('');
    const selectedExtraCourses = ref([]);
    const selectedExtraTurmas = ref([]);
    const activities = ref<Activity[]>([]);
    const activities2 = ref<Activity[]>([]);

    const currentStep = ref(1);
    const studentName = localStorage.getItem('selectedStudentName');
    const StudentMatriculaRegular = localStorage.getItem('StudentMatriculaRegular');
    const StudentSerie = localStorage.getItem('StudentSerie');
    const StudentTurno = localStorage.getItem('StudentTurno');
    const StudentGenero = localStorage.getItem('StudentGenero');
    const selectedStudentRA = localStorage.getItem('selectedStudentRA');
    const parentEmail = localStorage.getItem('parentEmail');
   
    const studentCourseCode =  ref(localStorage.getItem('studentCourseCode') ?? '');
    const backendUrl = process.env.VUE_APP_API_URL;
    const paidCourses = ref<Course[]>([]);

    const paidActivities = ref<PaidActivities>({}); // Isso agora é um objeto em vez de uma lista
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const optionalEnrollments = ref({ QTDMATRICULASSEMPLANOPAGAMENTO: 0 });
    const enrolledCourseCodes = ref<string[]>([]);
    const showSwimmingTermsModal = ref(false);

    const parentName = localStorage.getItem('parentName');
    const sessionTimeLeft = ref('');

 
const canSelectSecondOptionalCourse = computed(() => {
  // Verifica se o aluno está no ensino médio (códigos 04 ou 05)
  const isHighSchoolStudent = studentCourseCode.value === '04' || studentCourseCode.value === '05';
  // Verifica se já tem um curso opcional selecionado
  
  // Retorna verdadeiro se não for do ensino médio ou se ainda não tiver selecionado um curso opcional
  return !isHighSchoolStudent 
});


    
    // Função para calcular o tempo restante
    const calculateTimeLeft = () => {
      const expirationDateString = localStorage.getItem('expirationDate');
      const expirationDate = expirationDateString ? new Date(expirationDateString) : new Date();
      const now = new Date();
      const difference = expirationDate.getTime() - now.getTime();
      const timeLeftInSeconds = Math.max(Math.round(difference / 1000), 0);

      const minutes = Math.floor(timeLeftInSeconds / 60);
      const seconds = timeLeftInSeconds % 60;

      sessionTimeLeft.value = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Atualizar a cada segundo
    setInterval(calculateTimeLeft, 1000);


    onMounted(async () => {
      await fetchCourses();
      await fetchOptionalEnrollments(); // Certifique-se de que essa função é chamada após fetchCourses
    });

    

    const fetchCourses = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(`${backendUrl}/courses?IDHABILITACAOFILIAL=${StudentMatriculaRegular}`);
        allCourses.value = response.data; // Armazena todos os cursos
        courses.value = response.data; // Inicializa a lista de cursos mostrados
        isLoading.value = false;
      } catch (error) {
      
        toast.error('Erro ao carregar cursos');
        
        isLoading.value = false;
      }

    };
    
    const fetchPaidCourses = async () => {
      
      try {
        const response = await axios.get(`${backendUrl}/paid-courses?IDHABILITACAOFILIAL=${StudentMatriculaRegular}`);
        paidCourses.value = response.data;
      } catch (error) {
        toast.error('Erro ao buscar atividades');
        
      }
    };

    
    // Função para buscar cursos pagos
    const fetchPaidActivities = async (courseCode: string) => {
      try {
        const response = await axios.get(`${backendUrl}/paid-activities?CODCURSO=${courseCode}&IDHABILITACAOFILIAL=${StudentMatriculaRegular}&GENERO_ALUNO=${StudentGenero}&RA=${selectedStudentRA}`);
        paidActivities.value[courseCode] = response.data;
      } catch (error) {

        if (error instanceof Error) {
              // Agora você pode acessar error.message ou usar toString()
                toast.error(`Ocorreu um problema ao buscar atividades: ${error.message}`);
              } else {
             
                toast.error('Ops, não encontramos atividades disponiveis');
              }
        toast.error('Erro ao buscar atividades');
        console.error('Erro ao buscar atividades pagas:', error);
      }
    };

    const findCourseValue = (courseCode: any, classId: any) => {
        // Suponha que paidActivities.value[courseCode] contém os dados das atividades pagas para o curso
        // e que você precisa encontrar o VALOR_PARCELA específico para o classId fornecido.
        const activities = paidActivities.value[courseCode];
        if (!activities) return '0,00'; // Se não encontrar atividades para o curso, retorna '0,00'

        // Encontre a atividade correspondente ao classId e retorne seu VALOR_PARCELA
        // Isso depende da estrutura exata dos seus dados
        const activity = activities.find(activity => activity.IDHABILITACAOFILIAL === classId);
        return activity ? activity.VALOR_PARCELA : '0,00';
      };

    const updateCourseListForStep2 = () => {
      console.log(enrolledCourseCodes, 'enrolledCourseCodes');
      // Filtrar cursos com base no curso selecionado e nos cursos matriculados
      coursesForSecondStep.value = allCourses.value.filter((course) => {
        return course.CODCURSO !== selectedCourse.value && !enrolledCourseCodes.value.includes(course.CODCURSO);
      });
      console.log(coursesForSecondStep);
    };

 // Watcher para o primeiro curso selecionado
    watch(selectedCourse, async (newCourse: any) => {
      isLoading.value = true;
      if (newCourse) {
        try {
          const response = await axios.get(`${backendUrl}/classes?CODCURSO=${newCourse}&IDHABILITACAOFILIAL=${StudentMatriculaRegular}&GENERO_ALUNO=${StudentGenero}&RA=${selectedStudentRA}`);
          activities.value = response.data;
          if (response.data.some((activity: Activity) => activity.ATIVIDADE === 'Natação')) {
        showSwimmingTermsModal.value = true; // Exibir modal se o curso for Natação
      } else {
          if (currentStep.value === 1) {
              selectedFirstCourse.value = newCourse; // Armazene o curso escolhido no primeiro passo
            }
            isLoading.value = false;
          }
        } catch (error) {
          console.error('Erro ao buscar turmas:', error);
          isLoading.value = false;
        }
      }
    });

    

      // Watcher para o segundo curso selecionado
      watch(selectedCourse2, async (newCourse2) => {
        if (newCourse2) {
          isLoading.value = true;
          try {
            const response = await axios.get(`${backendUrl}/classes?CODCURSO=${newCourse2}&IDHABILITACAOFILIAL=${StudentMatriculaRegular}&GENERO_ALUNO=${StudentGenero}&RA=${selectedStudentRA}`);
            activities2.value = response.data;
            
            const isSwimming = response.data.some((activity:  Activity) => activity.ATIVIDADE === 'Natação');
            if (isSwimming) {
              showSwimmingTermsModal.value = true;
            } else {
              showSwimmingTermsModal.value = false;
            }
          } catch (error) {
            console.error('Erro ao buscar turmas:', error);
          } finally {
            isLoading.value = false;
          }
        }
      });

    watch(selectedExtraCourses, (newSelectedCourses, oldSelectedCourses) => {
        // Encontre quais cursos foram desmarcados
        isLoading.value = true;
        const deselectedCourses = oldSelectedCourses.filter(courseCode => !newSelectedCourses.includes(courseCode));
        
        // Limpe as turmas selecionadas para cada curso desmarcado
        deselectedCourses.forEach(clearSelectedClassesForCourse);

        // Continue com o carregamento das atividades para os cursos marcados
        newSelectedCourses.forEach(courseCode => {
          if (!oldSelectedCourses.includes(courseCode)) {
           console.log( fetchPaidActivities(courseCode));
          }
        });
        isLoading.value = false;
      }, { deep: true });

      const agreeToSwimmingTerms = async () => {
          showSwimmingTermsModal.value = false; // Fecha o modal
          isLoading.value = true; // Ativa o indicador de carregamento

          try {
            // Suponha que "selectedCourse.value" contenha o código do curso de natação
            const response = await axios.get(`${backendUrl}/classes?CODCURSO=${selectedCourse.value}&IDHABILITACAOFILIAL=${StudentMatriculaRegular}&GENERO_ALUNO=${StudentGenero}&RA=${selectedStudentRA}`);
            activities.value = response.data; // Atualiza as atividades com as turmas de natação
          } catch (error) {
            console.error('Erro ao buscar turmas de natação:', error);
            //toast.error('Erro ao carregar turmas de natação.');
          } finally {
            isLoading.value = false; // Desativa o indicador de carregamento
          }
        };


      const cancelSwimmingTerms = () => {
        // Fechar o modal e talvez limpar a seleção de natação se necessário
        window.location.reload();
      };

    const clearSelectedClassesForCourse = (courseCode: string) => {
      selectedExtraTurmas.value = selectedExtraTurmas.value.filter(
        (classId) => !paidActivities.value[courseCode].some(
          (activity) => activity.IDHABILITACAOFILIAL === classId
        )
      );
    };

    const beforeTabSwitch = async () => {
      if (isSessionExpired()) {
        router.push({ name: 'login' });  
        toast.success('Sua sesssão expirou! Acesse novamente para continuar');
      } else {
        // Antes de trocar a aba, atualize a lista de cursos do segundo passo.
        // Exclua os cursos já selecionados no primeiro passo e os cursos em que o aluno já está matriculado.
        coursesForSecondStep.value = allCourses.value.filter((course) => {
          return course.CODCURSO !== selectedCourse.value && 
                !enrolledCourseCodes.value.includes(course.CODCURSO);
        });

        // Continua com a busca dos cursos pagos.
        await fetchPaidCourses();
        
        return true; // Retorna true para permitir a mudança de aba
      }
      };

    
    const finalizeEnrollment = async () => {
      if (!selectedClassId.value && !selectedClassId2.value && selectedExtraTurmas.value.length === 0) {
        toast.error('Selecione pelo menos uma turma para concluir a matrícula.');
        return; // Não continue se nenhuma turma foi selecionada
      }
      if (isSubmitting.value) {
        return; // Impede múltiplas submissões se já estiver processando
      }
      isSubmitting.value = true; // Começa a submissão
        // Inclua os IDs das turmas regulares e das turmas extras
        isLoading.value = true;
        const allSelectedClassIds = [
          selectedClassId.value,
          selectedClassId2.value,
          ...selectedExtraTurmas.value
        ].filter(id => id !== null && id !== '');

        const studentRA = store.state.studentRA || localStorage.getItem('selectedStudentRA');

        if (allSelectedClassIds.length === 0 || !studentRA) {
          toast.error('Dados de matrícula incompletos.');
            /*alert('Dados de matrícula incompletos.');*/
          return;
        }

        try {
          const response = await axios.post(`${backendUrl}/enroll-student`, {
            studentRA,
            parentEmail,
            selectedClasses: allSelectedClassIds
          });

          console.log(response.data);
          toast.success('Matrícula realizada com sucesso!');
          toast.success('Verifique seu email para concluir assinatura do contrato');

          //isLoading.value = false;
          router.push({ name: 'StudentList' });
          isSubmitting.value = false; // Desativa o indicador de carregamento em caso de sucesso
        } catch (error) {
          toast.error('Houve um problema ao realizar matricula');
          isSubmitting.value = false; // Reativa o botão em caso de falha
          /*console.error('Erro ao realizar matrícula:', error);
          alert('Ocorreu um erro ao realizar a matrícula.');*/
        // isLoading.value = false;
        }finally {
          isLoading.value = false;  // Desativar indicador de carregamento
        }
      };

   function findCourseName(courseCode: string): string {
        // Busque primeiro em allCourses
        let course = allCourses.value.find(c => c.CODCURSO === courseCode);
        if (!course) {
          // Se não encontrar, busque em paidCourses
          course = paidCourses.value.find(c => c.CODCURSO === courseCode);
        }
        return course ? course.ATIVIDADE : 'Não selecionado';
      }

      function findClassName(classId: string): string {
        // Busque nas atividades regulares primeiro
        let activity = activities.value.find(a => a.IDHABILITACAOFILIAL === classId) ||
                      activities2.value.find(a => a.IDHABILITACAOFILIAL === classId);
        // Se não encontrar, busque nas atividades dos cursos extras
        if (!activity) {
          for (const courseCode in paidActivities.value) {
            activity = paidActivities.value[courseCode].find(a => a.IDHABILITACAOFILIAL === classId);
            if (activity) break;
          }
        }
        return activity ? `${activity.HORARIO} - ${activity.TURNO}` : 'Não selecionado';
      }

        function isClassFromCourse(classId: string, courseCode: string): boolean {
        return paidActivities.value[courseCode]?.some(activity => activity.IDHABILITACAOFILIAL === classId) ?? false;
        }

          // Função para calcular o total
    const calculateTotal = computed(() => {
      let total = 0;
      selectedExtraTurmas.value.forEach(classId => {
        selectedExtraCourses.value.forEach(courseCode => {
          if (isClassFromCourse(classId, courseCode)) {
            // Aqui você chama findCourseValue passando courseCode e classId
            // Supondo que retorna o valor como um número ou uma string numérica
            const value = parseFloat(findCourseValue(courseCode, classId));
            if (!isNaN(value)) {
              total += value;
            }
          }
        });
      });

      return total.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    });

       // Função para formatar valores como moeda
       const formatCurrency = (value: any) => {
      const numberValue = parseFloat(value);
      if (isNaN(numberValue)) return "R$ 0,00";

      return numberValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    };

        function  goToStudentList() {
          router.push({ name: 'StudentList' });
    }

    const fetchOptionalEnrollments = async () => {
      
      try {
            const response = await axios.get(`${backendUrl}/optional-enrollments?studentRA=${selectedStudentRA}`);
            if (Array.isArray(response.data) && response.data.length > 0) {
              optionalEnrollments.value = response.data[0];
         
              // Atualize os códigos dos cursos matriculados
              console.log(optionalEnrollments,'optionalEnrollments')
              enrolledCourseCodes.value = [];
              if (response.data[0].CODCURSO1) {
                enrolledCourseCodes.value.push(response.data[0].CODCURSO1);
              }
              if (response.data[0].CODCURSO2) {
                enrolledCourseCodes.value.push(response.data[0].CODCURSO2);
              }
            } else {
              optionalEnrollments.value = { QTDMATRICULASSEMPLANOPAGAMENTO: 0 };
            }
          } catch (error) {
            toast.error('Houve um problema ao tentar buscar as matrículas opcionais.');
            console.error('Erro ao buscar matrículas opcionais:', error);
            // Se houver um erro na API, mantenha o valor padrão
            optionalEnrollments.value = { QTDMATRICULASSEMPLANOPAGAMENTO: 0 };
          }
        };

    function logout() {
      
      localStorage.clear();
    
      // Limpa o estado do Vuex
      store.commit('clearToken'); // Ou a ação/mutação que você tem para limpar o token

      // Redireciona o usuário para a página de login
      router.push({ name: 'login' });
    }
// Métodos para logout e outros que você precisar

    return {
      courses,
      selectedCourse,
      selectedClassId,
      currentStep,
      activities,
      studentName,

      selectedClassId2,
      selectedCourse2,
      activities2,
      coursesForSecondStep,
      selectedExtraCourses,
      selectedExtraTurmas,
      allCourses,
      paidActivities,
      isLoading,
      isSubmitting,
      optionalEnrollments,
      showSwimmingTermsModal,
      canSelectSecondOptionalCourse,
      formatCurrency,
      findCourseValue,
      finalizeEnrollment,
      updateCourseListForStep2,
      beforeTabSwitch,
      fetchCourses,
      fetchPaidActivities,
      fetchPaidCourses,
      findCourseName,
      findClassName,
      isClassFromCourse,
      calculateTotal,
      goToStudentList,
      fetchOptionalEnrollments,
      agreeToSwimmingTerms,
      cancelSwimmingTerms,
      StudentSerie,
      StudentTurno,
      paidCourses,
      parentName,
        sessionTimeLeft,
      logout
      // inclua outros dados e métodos que você precisar expor
    };
  }
};
</script>

<style scoped>
@import "@/assets/navbar.css"; /* Importe os estilos da navbar aqui */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");

.page-container {
  padding: 1rem;
  background-color: #FED766; /* Cor de fundo amarela */
  /* Outros estilos que você quiser aplicar ao container da página */
}


.student-info {
  /* background: #FFF8E7; Fundo claro */
  padding: 10px;
  margin-bottom: 10px;
  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
  display: inline-block; /* Ajusta o tamanho do background ao conteúdo */
}

.student-name {
  margin: 0;
  color: #333;
  font-size: 1.5em; /* Tamanho grande para o nome */
}

.student-details {
  display: block; /* Coloca na próxima linha */
  color: #666;
  font-size: 1em; /* Tamanho menor para os detalhes */
  margin-top: 5px; /* Espaço entre o nome e os detalhes */
}

.wizard-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-width: 800px; /* Ou qualquer largura máxima que você preferir */
}

/* Estilos para os botões e selects dentro do wizard */
.wizard-container select,
.wizard-container button {
  width: 100%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.wizard-container button {
  background-color: #333; /* Cor escura para o botão */
  color: white;
  cursor: pointer;
}

.wizard-container button:hover {
  background-color: #555; /* Um tom ligeiramente mais claro para o hover */
}

.select-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.select-container label {
  margin-bottom: 10px;
}

.select-container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #fff; /* Adiciona uma borda branca */
  padding: 10px; /* Espaçamento interno para separar o conteúdo da borda */
  border-radius: 4px; /* Bordas arredondadas */
  background-color: #f8f8f8; /* Cor de fundo mais clara para cada item */
}

/* Estilos adicionais para a área de seleção de turma */
.class-container {
  margin-top: 20px; /* Espaço adicional acima da seleção de turma */
}

/* Estilos para o contêiner de select de curso para controlar o espaçamento */
.select-container {
  margin-bottom: 20px; /* Espaço abaixo do select do curso */
}

.class-container label {
  display: block; /* Faz o rótulo ser exibido como um bloco */
  font-weight: bold; /* Deixa o texto em negrito */
  margin-top: 20px; /* Aumenta o espaço acima do rótulo */
  margin-bottom: 10px; /* Aumenta o espaço abaixo do rótulo */
  color: #333; /* Define a cor do texto */
  font-size: 1.1em; /* Aumenta o tamanho da fonte */
}

/* Adicione estilos para os botões de rádio para que eles fiquem maiores */
.radio-label {
  display: flex;
  align-items: center; /* Centraliza verticalmente os itens */
  margin-bottom: 10px; /* Espaço entre as opções */
  border: 1px solid #fff; /* Adiciona uma borda branca */
  padding: 10px; /* Espaçamento interno para separar o conteúdo da borda */
  border-radius: 4px; /* Bordas arredondadas */
  background-color: #f8f8f8; /* Cor de fundo mais clara para cada item */
}

/* Estilos para os botões de rádio para que eles fiquem alinhados corretamente */
.custom-radio {
  margin-right: 10px; /* Espaço entre o botão rádio e o texto */
  cursor: pointer; /* Muda o cursor para indicar que é clicável */
}

/* Estilos para o texto que acompanha cada botão de rádio */
.radio-group label span {
  flex-grow: 1; /* Permite que o texto ocupe o espaço disponível */
  padding-left: 10px; /* Espaçamento à esquerda do texto */
}

/* Estilos para o contêiner que segura os rádios e as turmas, responsivo para colunas */
.radio-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Colunas responsivas */
  gap: 10px; /* Espaço entre as turmas */
}

/* Estilos para aumentar o tamanho dos botões de rádio */
.custom-radio {
  -webkit-appearance: none; /* Remove a aparência padrão para WebKit/Blink */
  appearance: none;
  background: #fff; /* Cor de fundo padrão */
  border: 2px solid #333; /* Adiciona borda mais espessa */
  border-radius: 50%; /* Torna o botão redondo */
  width: 24px; /* Aumenta a largura */
  height: 24px; /* Aumenta a altura */
  vertical-align: middle; /* Alinha verticalmente com o texto */
  transition: background-color 0.2s; /* Suaviza a transição da cor de fundo */
}

.custom-radio:checked {
  background-color: #333; /* Cor de fundo quando selecionado */
  border-color: #333; /* Cor da borda para coincidir com o fundo quando selecionado */
}

.custom-radio:focus {
  outline: none; /* Remove o contorno padrão quando focado */
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.5); /* Adiciona um sombreamento para indicar foco */
}

@media (max-width: 768px) { /* Ajuste para dispositivos móveis */
  .radio-group {
    grid-template-columns: 1fr; /* Coluna única para telas pequenas */
  }
}

/* Sobrescrevendo os estilos para os passos do wizard */
.form-wizard .wizard-icon-circle {
  border-color: #333 !important; /* Muda a cor da borda dos círculos */
}

.form-wizard .wizard-icon-circle .wizard-icon-container {
  background-color: #333 !important; /* Muda a cor de fundo dos círculos */
}

.form-wizard .wizard-icon {
  color: #fff !important; /* Muda a cor do ícone dentro do círculo */
}

.form-wizard .stepTitle {
  color: #333 !important; /* Muda a cor do texto do título do passo */
}

/* Você também pode precisar de !important nas classes para estados específicos como 'active' ou 'completed' se eles tiverem estilos inline */
.form-wizard .wizard-icon-circle.active {
  background-color: #333 !important;
}

.form-wizard .wizard-icon-circle.completed {
  background-color: #333 !important;
}


/* Estilos para a seleção de cursos extras */
.course-selection {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px; /* Espaço entre as seleções de curso */
  background-color: #f9f9f9; /* Um fundo leve para destacar a área de seleção de curso */
  border-radius: 4px; /* Bordas arredondadas */
}

/* Estilos para a seleção de turmas dentro de cada curso */
.turmas-container {
  padding-left: 20px; /* Espaço à esquerda para hierarquia visual */
  margin-top: 10px; /* Espaço acima da lista de turmas */
}

/* Estilos para cada turma */
.turma-selection {
  display: block; /* Cada turma em sua própria linha */
  margin-bottom: 5px; /* Espaço entre as turmas */
}

/* Estilos para o título da seleção de turmas para melhor destaque */
.course-selection > label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

/* Estilos para os checkboxes para melhorar a aparência */
input[type="checkbox"] {
  -webkit-appearance: none; /* Remover a aparência padrão para navegadores WebKit/Blink */
  appearance: none;
  background: #fff;
  border: 1px solid #333;
  padding: 9px;
  border-radius: 3px; /* Bordas levemente arredondadas */
  display: inline-block;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #333;
  color: #fff;
}

input[type="checkbox"]:checked:after {
  content: ""; /* Adiciona um ícone de verificação */
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 3px;
  color: #fff;
}

.course-price-notice p {
  font-size: 1rem; /* Ajuste o tamanho da fonte conforme necessário */
  color: #333; /* A cor do texto */
  padding: 10px;
  background-color: #f8f8f8; /* Um fundo leve, se desejar */
  border-radius: 4px; /* Bordas arredondadas para a nota */
  margin-bottom: 20px; /* Espaço antes da lista de cursos */
}

.turma-price {
  display: block;
  font-size: 0.9rem;
  color: #888; /* A cor do texto para o preço */
  margin-top: 5px; /* Espaço acima do texto do preço */
}

/* Estilos para a seção de resumo da matrícula */
.resumo-matricula {
  background-color: #fff; /* Fundo branco para o resumo */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.resumo-matricula h2,
.resumo-matricula h3 {
  color: #333;
  text-align: left;
}

.resumo-matricula p,
.resumo-matricula li {
  color: #333;
  text-align: left;
  line-height: 1.6; /* Espaçamento da linha para melhor legibilidade */
}

.resumo-matricula ul {
  list-style-type: none; /* Remove os marcadores da lista */
  padding: 0;
}

.resumo-matricula li {
  padding: 5px 0; /* Espaçamento superior e inferior para cada item da lista */
}

/* Estilos para o total */
.resumo-matricula .total {
  font-weight: bold;
  margin-top: 10px;
}

/* Você pode precisar ajustar os estilos para os valores */
.valor {
  font-weight: bold;
}

/* Responsividade */
@media (max-width: 768px) {
  .resumo-matricula {
    padding: 15px;
  }
}


/* Título do curso extra em negrito */
.course-title {
  font-weight: bold;
  margin-bottom: 0.5rem; /* Espaçamento abaixo do título do curso */
}

/* Espaçamento uniforme para as turmas */
.turma-item {
  margin-top: 0.25rem; /* Espaçamento acima de cada turma */
  margin-bottom: 0.25rem; /* Espaçamento abaixo de cada turma */
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 1.2em;
  color: #333;
}

.student-select-button {
  background-color: #ffd700; /* ou a cor que melhor se encaixe no seu design */
  color: #000; /* cor do texto */
  border: none; /* sem borda */
  padding: 10px 20px; /* preenchimento para torná-lo mais largo e alto */
  font-weight: bold; /* texto em negrito */
  border-radius: 5px; /* cantos arredondados */
  cursor: pointer; /* cursor do mouse como ponteiro */
  transition: background-color 0.3s ease; /* transição suave para hover */
  margin-right: 10px; /* margem à direita para não colar no botão Sair */
}

.student-select-button:hover {
  background-color: #e0c200; /* cor mais escura no hover */
}

.radio-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.class-info {
  margin-bottom: 5px; /* Ajuste conforme necessário para dar espaço entre as informações e o indicador */
}

.wait-list {
  color: red;
  font-weight: bold;
  font-size: 0.9em;
}

.enrollment-message {
  margin: 10px 0;
  padding: 10px;
  background-color: #ffecb3; /* Cor de fundo amarela clara para chamar atenção */
  color: #333; /* Cor de texto marrom escuro para contraste */
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ffddc1;
}

/* Estilos para o modal de termos */
.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.terms-modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  color: #333; /* Cor do texto */
  font-family: 'Arial', sans-serif; /* Altere conforme a fonte do seu tema */
}

.terms-text {
  margin-bottom: 20px;
  text-align: left; /* Alinhar o texto à esquerda */
  font-size: 14px; /* Tamanho do texto */
  line-height: 1.6; /* Espaçamento das linhas */
  height: 300px; /* Altura fixa para a área de texto */
  overflow-y: scroll; /* Adicionar barra de rolagem para texto longo */
}
.terms-modal h2 {
  text-align: center;
  margin-bottom: 20px;
}

.terms-actions {
  display: flex; /* Usar flexbox para alinhamento dos botões */
  justify-content: space-between; /* Separar os botões uniformemente */
  padding: 0 20px; /* Adicionar padding nas laterais para não tocar as bordas */
  margin-top: 20px;
}

.terms-actions button {
  margin-left: 10px;
}


.terms-agree-button, .terms-cancel-button {
  flex: 1; /* Fazer os botões terem a mesma largura */
  margin: 0 10px; /* Adicionar margem entre os botões */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px; /* Tamanho do texto dos botões */
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s; /* Transição suave para o hover */
}

.terms-agree-button {
  background-color: #4CAF50; /* Cor do botão Concordar */
  color: white;
}

.terms-agree-button:hover {
  background-color: #45a049; /* Cor do hover */
}

.terms-cancel-button {
  background-color: #f44336; /* Cor do botão Cancelar */
  color: white;
}

.terms-cancel-button:hover {
  background-color: #d32f2f; /* Cor do hover */
}

@media (max-width: 768px) {
  .terms-actions {
    flex-direction: column; /* Empilhar os botões verticalmente */
  }

  .terms-agree-button, .terms-cancel-button {
    margin-top: 10px; /* Adicionar espaço entre os botões quando empilhados */
    margin-bottom: 10px;
  }
}

</style>